import React from 'react'
import { graphql } from 'gatsby'

import { Contact as ContactPage } from '../components/Pages'

const Kontakt = props => <ContactPage {...props} />

export default Kontakt

export const query = graphql`
  query Kontakt($locale: String!) {
    image: file(sourceInstanceName: {eq: "images"}, name: {eq: "contact-header"}) {
      name
      childImageSharp {
        fluid(maxWidth: 709, maxHeight: 705, quality: 80) {
          src
          srcSet
          sizes
          presentationWidth
          aspectRatio
        }
      }
    }
    seo: allFile(filter: {sourceInstanceName: {eq: "pages"}, name: {eq: $locale}}) {
      nodes {
        name
        childPagesJson {
          contact {
            title
            description
          }
        }
      }
    }
  }
`
